.slider {
    margin-bottom: 20px;
    font-weight: 600;

    .slider-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .slider-input {
            -webkit-appearance: none;
            width: 100%;
            height: 3px;
            border-radius: 3px;
            background: #0006244d;
            outline: none;
            -webkit-transition: 0.2s;
            transition: opacity 0.2s;

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #116dff;
                cursor: pointer;
            }

            &::-moz-range-thumb {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #116dff;
                cursor: pointer;
            }
        }

        .value {
            width: 60px;
            font-weight: 500;
            color: #333853;
            margin-left: 10px;
        }
    }

    .title {
        margin-bottom: 5px;
    }
}
