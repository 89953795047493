.platform-switcher {
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    border-bottom: 2px solid #fff;
    margin-bottom: 15px;

    .platform {
        border-bottom: 2px solid #fff;
        padding-bottom: 7px;
        color: #000;
        margin-bottom: -2px;
        cursor: pointer;
    }

    .selected {
        border-bottom: 2px solid #116dff;
        color: #116dff;
    }
}
