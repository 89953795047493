.radio-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .radio-container {
        display: flex;
        align-items: center;

        input {
            align-items: center;
            margin: 0 8px 0 0;
        }
    }
}
