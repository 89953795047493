.settings {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 20px);
    font-size: 14px;

    &__section-left {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding-left: 5px;

        .tabs {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 10px;
        }

        .support {
            margin-left: 10px;
            cursor: pointer;
        }

        .sidebar-button {
            border: none;
            font-weight: 500;
            cursor: pointer;
            margin: 3px 0;
            padding: 0 10px;
            height: 32px;
            border-radius: 20px;
            text-transform: capitalize;
        }

        .upgrade-button {
            background-color: #116dff;
            color: white;
        }

        .premium {
            margin-right: 10px;
        }
    }

    &__section-right {
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #e7f0ff;
        border-radius: 8px;
        margin: 10px 5px 0 5px;
        align-items: center;
        overflow: scroll;

        .connect-buttons-container {
            display: flex;
            flex-direction: column;

            .connect-button {
                border: 1px solid #00062433;
                font-size: 16px;
                font-weight: 500;
                height: 44px;
                background-color: #fff;
                border-radius: 100px;
                padding: 0 20px;
                cursor: pointer;

                .connect {
                    margin-right: 10px;
                }
            }
        }

        .connect-help {
            position: absolute;
            bottom: 20px;
            color: #00062480;
            text-decoration: underline;
            display: flex;
            align-items: center;
            cursor: pointer;

            .help {
                margin-right: 5px;
            }
        }

        .card {
            box-sizing: border-box;
            background-color: white;
            border-radius: 8px;
            padding: 10px;
            width: 95%;
            text-align: left;
            margin-bottom: 5px;
            display: flex;
            flex-direction: column;

            &__title {
                font-weight: 600;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &__buttons {
                display: flex;
                width: 100%;
                justify-content: flex-start;
            }

            &__css {
                border: none;
                background-color: #eceff3;
                padding: 5px;
                font-family: 'Wix Madefor Text', sans-serif;
            }

            .color-sample-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                .color-sample {
                    width: 32px;
                    height: 32px;
                    border: 2px solid #b2b4bd;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
}

.color-input {
    width: 100px;
    height: 30px;
    border: 1px solid rgb(214, 230, 254);
    border-radius: 6px;
    padding: 5px 10px;
    margin-top: 10px;
}
