.carousel-container {
    width: 100%;
    margin-top: 28px;
    position: relative;
    overflow: hidden;
}

.inner-carousel {
    width: 100%;
    overflow: hidden;
}

.track {
    display: inline-flex;
    height: 100%;
    transition: transform 0.2s ease-in-out;
    flex: 1 1 0px;
}

.nav button {
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.9);
}

.nav .prev {
    display: none;
}

.nav .prev.show {
    display: block;
}

.nav .next.hide {
    display: none !important;
}

.arrow-right {
    margin-right: 10px;
}

.arrow-left {
    transform: rotate(180deg);
    margin-left: 10px;
}
