.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1050px) {
        flex-direction: column;
        justify-content: flex-start;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .close {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 100;

        @media only screen and (max-width: 1050px) {
            top: 20px;
            right: 20px;
        }
    }

    .arrow {
        width: 24px;
        height: 24px;
        cursor: pointer;

        @media only screen and (max-width: 1050px) {
            display: none;
        }
    }

    .arrow-left {
        transform: rotate(180deg);
        margin-right: 20px;
    }

    .arrow-right {
        margin-left: 20px;
    }
}

.post-container {
    width: 980px;
    max-height: 80%;
    display: flex;

    @media only screen and (max-width: 1050px) {
        flex-direction: column;
        max-height: 100%;
        min-height: 100%;
        height: 100%;
        width: 100%;
        height: auto !important;
    }
}

.media-container {
    position: relative;

    .media {
        width: 600px;
        height: 100%;
        object-fit: cover;

        @media only screen and (max-width: 1050px) {
            width: 100%;
        }
    }

    .watermark {
        position: absolute;
        right: 5px;
        top: 5px;
    }
}

.sidebar {
    position: relative;
    width: 100%;
    background-color: white;

    @media only screen and (max-width: 1050px) {
        height: 100%;
    }

    @media only screen and (min-width: 1051px) {
        overflow-y: scroll;
    }

    &__header {
        position: sticky;
        top: 0;
        display: flex;
        padding: 15px;
        border-bottom: 1px solid rgba(18, 18, 18, 0.2);
        z-index: 10;
        background-color: #fff;
        width: 350px;

        @media only screen and (max-width: 1050px) {
            display: none;
        }
    }

    &__header__mobile {
        position: fixed;
        top: 0;
        display: flex;
        padding: 15px;
        z-index: 10;
        background-color: #fff;
        width: 100%;

        @media only screen and (min-width: 1051px) {
            display: none;
        }
    }

    &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 8px;
        margin-left: 10px;
    }

    &__username {
        font-weight: 600;
    }

    &__date {
        font-weight: 500;
        font-size: 12px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 15px;
        min-height: calc(100% - 150px);
    }

    &__description {
        margin-bottom: 15px;
        font-weight: 600;
    }

    &__comments {
        display: flex;
        flex-direction: column;
        font-size: 14px;
    }

    &__footer {
        position: sticky;
        bottom: 0;
        z-index: 10;
        background-color: #fff;
        width: 350px;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        padding: 15px;
        border-top: 1px solid rgba(18, 18, 18, 0.2);

        @media only screen and (max-width: 1050px) {
            display: none;
        }
    }
}
