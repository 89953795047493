.comment {
    margin-bottom: 20px;

    &__top-row {
        display: flex;
        width: 100%;
        margin-bottom: 2px;
    }

    &__bottom-row {
        display: flex;
        font-weight: 500;
        font-size: 14px;
    }

    &__author {
        font-weight: 600;
        margin-right: 10px;
    }

    &__date {
        margin-right: 30px;
    }

    .replies-text {
        margin-top: 5px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
    }

    &__reply {
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 0px;
    }
}
