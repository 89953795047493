.header {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    max-width: 600px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }

    &__avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    &__data-container {
        display: flex;
        flex-direction: column;
    }

    &__first-row {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;

        @media only screen and (max-width: 380px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__username {
        font-weight: 700;
    }

    &__follow {
        border: none;
        width: 83px;
        height: 30px;
        padding: 4px, 20px;
        border-radius: 4px;
        font-size: 18px;

        @media only screen and (max-width: 380px) {
            flex-direction: column;
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 10px;
            align-items: center;
        }
    }

    &__second-row {
        margin-top: 10px;
        display: flex;

        @media only screen and (max-width: 400px) {
            flex-direction: column;
        }
    }

    &__count {
        @media only screen and (max-width: 400px) {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 6px;
        }
    }

    &__third-row {
        margin-top: 10px;
    }

    &__fourth-row {
        margin-top: 5px;
        font-weight: 400;
    }
}
