.checkbox-container {
    display: flex;
    justify-content: space-between;
    height: 36px;

    .title {
        margin-top: 3px;
    }
}

.switch-container {
    display: flex;
    align-items: flex-start;

    .premium-icon {
        margin-right: 10px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 44px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 22px;

        &::before {
            position: absolute;
            content: url('../images/checked.svg');
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}

.slider.off {
    &::before {
        content: url('../images/unchecked.svg');
    }
}

input:checked + .slider {
    background-color: #116dff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #116dff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
