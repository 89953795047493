.thumbnail {
    cursor: pointer;
    position: relative;

    &__image {
        margin: 0;
        display: flex;

        img {
            object-fit: cover;
        }
    }

    &__watermark {
        position: absolute;
        right: 15px;
        top: 15px;
    }
}
