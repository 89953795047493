.image-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    &__image {
        margin-bottom: 5px;
    }

    &__title {
        text-transform: capitalize;
    }
}
