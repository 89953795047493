.gallery {
    display: grid;
    justify-content: center;
    margin-top: 28px;
}

.load-more {
    width: 100%;
    text-align: center;

    &__button {
        width: 95px;
        height: 32px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 23px;
        border: none;
        border-radius: 4px;
    }
}
