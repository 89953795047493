.dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__input {
        width: 170px;
        height: 42px;
        border-color: rgb(214, 230, 254);
        border-radius: 6px;
        padding: 5px 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('../images/dropdown_arrow.svg');
        background-repeat: no-repeat;
        background-position: 92% 50%;
    }
}
